<template>
  <div>
    <Toolbar
      :handle-reset="resetForm"
      :handle-submit="onSendForm"
    />
    <TenantSeminarSettingForm
      :errors="violations"
      :values="item"
      ref="createForm"
    />
    <Loading :visible="isLoading" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { createHelpers } from 'vuex-map-fields';
  import TenantSeminarSettingForm from '../../components/tenantSeminarSetting/Form';
  import Loading from '../../components/Loading';
  import Toolbar from '../../components/Toolbar';
  import CreateMixin from '../../mixins/CreateMixin';

  const servicePrefix = 'TenantSeminarSetting';

  const { mapFields } = createHelpers({
    getterType: 'tenantSeminarSetting/getField',
    mutationType: 'tenantSeminarSetting/updateField'
  });

  export default {
    name: 'TenantSeminarSettingCreate',
    servicePrefix,
    mixins: [CreateMixin],
    components: {
      Loading,
      Toolbar,
      TenantSeminarSettingForm,
    },
    data() {
      return {
        item: {
          numberRange: {},
          seminarPackageNumberRange: {},
        },
        createMessage: this.$t("tenantSeminarSettingUpdateSuccess"),
      };
    },
    computed: {
      ...mapFields(['error', 'isLoading', 'created', 'violations'])
    },
    methods: {
      ...mapActions('tenantSeminarSetting', {
        create: 'create',
      }),
    },
  }
</script>
